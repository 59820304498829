<template>
  <div class="dl" ref="vlbox">
      <p class="title">         
          <p class="title">
          <span v-show="data.type==1">出库清单</span>
          <span v-show="data.type==2">实际使用清单</span>
          <span @click="close" class="pointer">
            <i class="el-icon-circle-close"></i>
          </span>
        
      </p>
      <el-table :data="tableData"  border max-height='700'>
            <!-- <el-table-column type="index" label="排序" width="60">
            </el-table-column> -->
            <el-table-column prop="deviceNum" label="设备编号" 
            width="220" :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="productName" label="设备名称"
            width="140" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="merchantName" label="供应商简称"
            width="120" :show-overflow-tooltip="true">
            </el-table-column>
          </el-table>
          <p style="margin:16px 6px;gont-size:14px;color:#333">
            合计：{{tableData.length}}条
          </p>
  </div>
</template>

<script>
import {
stockRequisition,stockUse
} from "@/api/apis.js";
export default {
  data() {
    return {

      tableData:[],
   
    };
  },
  methods:{
    close(){
        this.$emit("close")
        this.form={}
      },
  },
  props: ["data"],
  mounted() {  
    if(this.data.type==1){
        stockRequisition({orderId:this.data.orderId}).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
        } else {
          this.tableData = [];
        }
      });
    }else  if(this.data.type==2){
        stockUse({orderId:this.data.orderId}).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
        } else {
          this.tableData = [];
        }
      });
    }
  },
  
};
</script>

<style lang="less" >
.dl {
      width: 500px;
      max-height: 800px;
      overflow: auto;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      position: relative;
      .title {
        color: #2b2e36;
        line-height: 50px;
        font-weight: bold;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #dddddd;
        i {
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
</style>