<template>
  <div class="Device1122" ref="vlbox">
    <div class="index_main111" ref="index_main">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定删除该条订单吗?</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="deletePro"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <div class="index_right">
        <p class="search">
          <el-select
            clearable
            v-model="searchForm.status"
            v-if="permissoin.includes('query')"
            size="mini"
          >
            <el-option
              v-for="item in option1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
              v-if="permissoin.includes('query')"
              v-model="searchForm.handsPersonId"
              clearable filterable
              size="mini"
              placeholder="实施人"
            >
              <el-option
                v-for="item in option6"
                :key="item.userId"              
                :label="item.nickName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
            <el-date-picker
              v-if="permissoin.includes('query')"
              v-model="searchForm.materialDate"
              format="yyyy-MM-dd"
              size="mini"
              value-format="yyyy-MM-dd HH:mm:ss"
                type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          <el-input
            v-if="permissoin.includes('query')"
            type="text"
            size="mini"
            clearable
            v-model="searchForm.keywords"
            placeholder=" 请输入关键字"
          ></el-input>
          <button
            class="btn1"
            v-if="permissoin.includes('query')"
            @click="
              currentPage2 = 1;
              getData();
            "
          >
            <i class="el-icon-search"></i>
            查询
          </button>
          <button
            class="btn2"
            v-if="permissoin.includes('add')"
            @click="
              modal = 1;
              modalState = 0;
              form.status = -2;
            "
          >
            <i class="el-icon-plus"></i>
            新增
          </button>
          <button
            class="btn1"
            v-if="permissoin.includes('export')"
            @click="textExport"
          >
            <i class="el-icon-upload2"></i>
            导出
          </button>
        </p>
        <div class="table srcollstyle">
          <el-table :data="tableData" style="width: 98%" border>
            <el-table-column type="index" label="排序" width="80">
              <template slot-scope="scope">
                {{ (currentPage2 - 1) * 10 + scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="项目名称"
              :show-overflow-tooltip="true"
              width="260"
            >
            </el-table-column>
            <el-table-column prop="areaName" label="项目区域">
              <template slot-scope="scope">
                {{ areaNameChange(scope.row.areaName) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="contractorsName"
              label="参建单位"
              :show-overflow-tooltip="true"
              width="200"
            >
            </el-table-column>
            <el-table-column prop="creatorName" label="申请人">
            </el-table-column>
            <el-table-column prop="handsPersonName" label="实施人">
            </el-table-column>
            <el-table-column prop="allPrice" label="订单总价" :show-overflow-tooltip="true"
              width="100" v-if="permissoin.includes('money')">
              <template slot-scope="scope">
                  <span class="colorred">￥{{fmoney(scope.row.allPrice,2)}}</span>
                    </template>
            </el-table-column>
            <el-table-column label="计划类型">
              <template slot-scope="scope">
                <span v-if="scope.row.orderType == 1">初始计划</span>
                <span v-if="scope.row.orderType == 2">补充计划</span>
              </template>
            </el-table-column>
            <el-table-column prop="materialDate" label="实施日期">
            </el-table-column>
            <el-table-column prop="orgName" label="计划状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1" class="colororange"
                  >待审核</span>
                <span v-if="scope.row.status == -1">已撤回</span>
                <span v-if="scope.row.status == 2" class="colorred">驳回</span>
                <span v-if="scope.row.status == 3" class="colorblue"
                  >待实施</span
                >
                <span v-if="scope.row.status == 4" class="colorblue"
                  >待分配</span
                >
                <span v-if="scope.row.status == 0" class="colorgreen"
                  >已完成</span
                >
                <span v-if="scope.row.status == 5" class="colorblue"
                  >待领取</span
                >
                <span v-if="scope.row.status == 9" class="colorred"
                  >安装退回</span
                >
                <span v-if="scope.row.status == 8" class="colorred"
                  >安装失败</span
                >
                <span v-if="scope.row.status == 7" class="colorblue"
                  >安装待确认</span
                >
                <span v-if="scope.row.status == 6" class="colorblue"
                  >待出库</span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="el-icon-search"
                      v-if="permissoin.includes('query')"
                      :command="{ num: 2, data: scope.row }"
                      >查看</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="el-icon-edit-outline"
                      v-show="
                        scope.row.status == 1 && permissoin.includes('audit')
                      "
                      :command="{ num: 4, data: scope.row }"
                      >审核</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="el-icon-price-tag"
                      v-show="
                        scope.row.status == 4 && permissoin.includes('send')
                      "
                      :command="{ num: 5, data: scope.row }"
                      >指派实施</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="el-icon-receiving"
                      v-show="
                        scope.row.status == 5 && permissoin.includes('get')
                      "
                      :command="{ num: 6, data: scope.row }"
                      >材料申领</el-dropdown-item
                    >
                    <el-dropdown-item
                      icon="el-icon-refresh-left"
                      v-show="
                        ((scope.row.orderType == 1 && scope.row.status == 1) ||
                          (scope.row.orderType == 2 &&
                            scope.row.status == 4)) &&
                        permissoin.includes('withdraw')
                      "
                      :command="{ num: -1, data: scope.row }"
                      >撤回</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-show="
                        ((scope.row.orderType == 1 && scope.row.status == 1) ||
                          (scope.row.orderType == 2 &&
                            scope.row.status == 4)) &&
                        permissoin.includes('delete')
                      "
                      icon="el-icon-delete"
                      :command="{ num: 3, data: scope.row }"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage2"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="modal" v-show="modal == 1">
      <div class="inner">
        <p class="title">
          <span v-if="modalState == 0">新增订单</span>
          <span v-else>订单详情</span>
          <span
            @click="
              modal = 0;
              netArrUpdate1 = [{ materialType: 1 }];
              netArrUpdate2 = [{ materialType: 2 }];
              netArrUpdate3 = [{ materialType: 3 }];
              form = { allPrice: 0, allTax: 0 };
              slState = 0;
            "
            class="pointer"
          >
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div style="display: flex">
          <div class="modal_center scrollbar_width0" style="width: 480px">
            <p>
              <span class="spaa"><span class="star">* </span>代理商</span>
              <el-select
                v-model="form.merchantId"
                size="mini"
                placeholder="请选择"
                clearable
                filterable
                :disabled="modalState != 0"
              >
                <el-option
                  v-for="item in option50"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </p>
            <p>
              <span class="spaa"><span class="star">* </span>项目名称</span>
              <el-select
                @change="pjChange"
                v-model="form.projectId"
                clearable
                filterable
                size="mini"
                placeholder="请选择"
                :disabled="modalState != 0"
              >
                <el-option
                  v-for="item in option5"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </p>
            <p>
              <span class="spaa"><span class="star">* </span>计划类型</span>
              <el-input
                v-model="sn"
                disabled
                placeholder="请输入"
                size="mini"
              ></el-input>
            </p>
            <p>
              <span class="spaa"><span class="star">* </span>实施日期</span>
              <el-date-picker
                :disabled="modalState != 0"
                v-model="form.materialDate"
                :picker-options="pickerOptions1"
                type="date"
                value-format="yyyy-MM-dd"
                size="mini"
                placeholder="选择日期"
              >
              </el-date-picker>
            </p>

            <p style="display: flex">
              <span class="spa" style="width: 95px">备注</span>
              <el-input
                type="textarea"
                :disabled="modalState != 0"
                v-model="form.remark"
                size="mini"
              ></el-input>
            </p>
            <p v-show="modalState == 5">
              <span class="spaa"><span class="star">* </span>实施人</span>
              <el-select
                v-model="form.handsPersonId"
                size="mini"
                placeholder="请选择"
                clearable
                filterable
                :disabled="modalState != 5"
              >
                <el-option
                  v-for="item in option6"
                  :key="item.userId"
                  :label="item.nickName"
                  :value="item.userId"
                >
                </el-option>
              </el-select>
            </p>

            <div v-show="form.status == 0 || form.status == 9" style="display:flex">
              <span class="spaa" style='min-width:80px'><span class="star">* </span>现场照片</span>
              <div>
                <el-image
                v-for="item in srcList"
                :key="item"
                style="width: 80px; height: 100px; margin-right: 20px"
                :src="item"
                :preview-src-list="srcList"
              >
              </el-image>
              </div>
              
            </div>
            <p v-show="form.status == 0 || form.status == 9">
              <span class="spaa"><span class="star">* </span>安装结果</span>
              <el-input
                v-model="form.materialRemark"
                placeholder="请输入"
                size="mini"
              ></el-input>
            </p>
            <p
              class="disabledred"
              v-show="
                modalState == 0 &&
                permissoin.includes('money')
              "
            >
              <span class="spaa"><span class="star">* </span>税金（元）</span>
              <el-input v-model="form.allTax" disabled size="mini"></el-input>
            </p>
            <p
              class="disabledred"
              v-show="
                modalState != 5 &&
                modalState != 6 &&
                permissoin.includes('money')
              "
            >
              <span class="spaa"><span class="star">* </span>含税总价</span>
              <el-input v-model="form.allPrice" disabled size="mini"></el-input>
            </p>
             <p v-show="
                modalState != 0&&permissoin.includes('money')
              ">
              <span class="spa">硬件成本</span>
              <!-- <span>￥{{form.yjcb}}</span> -->
              <el-input v-model="form.yjcb" disabled size="mini"></el-input>
            </p>
            <p v-show="
                modalState != 0&&
                permissoin.includes('money')
              ">
              <span class="spa">税金</span>
              <!-- <span>￥{{form.sj}}</span> -->
              <el-input v-model="form.sj" disabled size="mini"></el-input>
            </p>
            <p v-show="
                modalState != 0&&
                permissoin.includes('money')
              ">
              <span class="spa">管理费</span>
              <!-- <span>￥{{form.glf}}</span> -->
              <el-input v-model="form.glf" disabled size="mini"></el-input>
            </p>
            <p class="disabledred" v-show="
                modalState != 0&&
                permissoin.includes('money')
              ">
              <span class="spa">毛利润</span>
              <!-- <span>￥{{form.mlr}}</span> -->
              <el-input v-model="form.mlr" disabled size="mini"></el-input>
            </p>
            <p class="disabledred" v-show="
                modalState != 0&&
                permissoin.includes('money')
              ">
              <span class="spa">毛利率</span>
              <!-- <span>{{form.mlv*100}}%</span> -->
              <el-input v-model="form.mlv" disabled size="mini"></el-input>
            </p>
             <p
              style="display: flex"
              v-show="form.status == 1 || form.status == 2"
            >
              <span class="spa" style="width:96px">审批意见</span>
              <el-input
              type="textarea"
                v-model="form.auditOpinion"
                :disabled='form.status == 2'
                size="mini"
                style="color: #d62829"
              ></el-input>
            </p>
            <div class="text_item" v-show="slState == 1">
              <span>*</span>申领清单
              <span style="margin-left: 86px">计划用量</span
              ><span style="margin-left: 30px">申领数量</span>
            </div>

            <div
              v-show="slState == 1"
              class="net"
              v-for="item in netArrUpdate4"
              :key="item.key"
            >
              <el-select
                v-model="item.productId"
                clearable
                filterable
                size="mini"
                placeholder="请选择"
                disabled
              >
                <el-option
                  v-for="item in option4"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="item.planNumber"
                disabled
                size="mini"
              ></el-input>
              <el-input
                v-model="item.applyNumber"
                :max="item.operNumber"
                placeholder="请输入"
                size="mini"
              ></el-input>
            </div>
          </div>
          <div class="modal_center scrollbar_width0" style="width: 840px">
            <div class="text_item">
              <span>*</span>硬件材料
              <span v-show="modalState == 0" @click="addFormUpdateItem(1)">
                <i class="el-icon-plus"></i>
                增加材料</span
              >
            </div>
            <div class="jstitle">
              <span>名称</span>
              <span>数量</span>
              <span v-show="modalState != 5 && modalState != 6">单价</span>
              <span v-show="modalState != 5 && modalState != 6">税率（%）</span>
              <span v-show="modalState != 5 && modalState != 6">税金</span>
              <span v-show="modalState != 5 && modalState != 6">含税单价</span>
              <span v-show="modalState != 5 && modalState != 6">总价</span>
            </div>
            <div
              class="net"
              v-for="(item, index) in netArrUpdate1"
              :key="item.key"
            >
              <el-select
                v-model="item.materialId"
                clearable
                filterable
                size="mini"
                placeholder="请选择"
                :disabled="modalState == 6"
              >
                <el-option
                  v-for="item in option4"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span
                    style="margin-right: 12px; color: #333; font-size: 12px"
                    >{{ item.name }}</span
                  >
                  <span style="margin-right: 12px; color: #333; font-size: 12px"
                    >库存:{{ item.stock }}</span
                  >
                </el-option>
              </el-select>

              <el-input
                :disabled="modalState == 6"
                @input="
                  checkNum(
                    1,
                    item,
                    netArrUpdate1,
                    index,
                    item.operNumber,
                    item.materialId
                  )
                "
                v-model="item.operNumber"
                placeholder="请输入"
                size="mini"
              ></el-input>
              <el-input
                v-show="
                  modalState != 5 &&
                  modalState != 6 &&
                  permissoin.includes('money')
                "
                v-model="item.unitPrice"
                disabled
                placeholder="自动生成"
                size="mini"
              ></el-input>
              <el-input
                v-show="modalState != 5 && modalState != 6"
                v-model="item.taxRate"
                @input="
                  taxRateGetInfo(item.taxRate, item, netArrUpdate1, index)
                "
                size="mini"
                placeholder="整数如13"
              ></el-input>
              <el-input
                v-show="
                  modalState != 5 &&
                  modalState != 6 &&
                  permissoin.includes('money')
                "
                disabled
                v-model="item.taxMoney"
                placeholder="自动生成"
                size="mini"
              ></el-input>

              <el-input
                v-show="modalState != 5 && modalState != 6"
                @input="
                  taxUnitPriceGetInfo(
                    item.taxUnitPrice,
                    item,
                    netArrUpdate1,
                    index
                  )
                "
                v-model="item.taxUnitPrice"
                placeholder="请输入"
                size="mini"
              ></el-input>

              <span class="disabledred">
                <el-input
                  v-show="
                    modalState != 5 &&
                    modalState != 6 &&
                    permissoin.includes('money')
                  "
                  v-model="item.taxLastMoney"
                  disabled
                  placeholder="自动生成"
                  size="mini"
                ></el-input>
              </span>
              <div style="display: flex; margin-top: 6px">
                <p class="line"></p>
                <span
                  style="
                    cursor: pointer;
                    position: relative;
                    top: -6px;
                    color: #d62829;
                    margin-left: 6px;
                  "
                  @click="deletenvritem(index, 1)"
                  v-show="index > 0 && modalState == 0"
                  ><i class="el-icon-close">删除</i></span
                >
              </div>
            </div>
            <p v-show="form.status == 0">
              <span
                class="ck"
                @click="
                  indexModal = 1;
                  transData = { orderId: form.orderId, type: 1 };
                "
                >查看出库清单</span
              >
              <span
                class="ck"
                @click="
                  indexModal = 1;
                  transData = { orderId: form.orderId, type: 2 };
                "
                >查看实际使用清单</span
              >
            </p>
            <div
              class="text_item"
              v-show="modalState != 6 && netArrUpdate2.length > 0"
            >
              <span>*</span>系统软件
              <span v-show="modalState == 0" @click="addFormUpdateItem(2)">
                <i class="el-icon-plus"></i>
                增加软件</span
              >
            </div>

            <div
              v-show="modalState != 6"
              class="net"
              v-for="(item, index) in netArrUpdate2"
              :key="item.key"
            >
              <el-select
                v-model="item.materialId"
                size="mini"
                placeholder="请选择"
                clearable
                filterable
              >
                <el-option
                  v-for="item in option2"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="item.operNumber"
                @input="checkNum(2, item, netArrUpdate2, index)"
                placeholder="请输入"
                size="mini"
              ></el-input>
              <el-input
                v-show="
                  modalState != 5 &&
                  modalState != 6 &&
                  permissoin.includes('money')
                "
                v-model="item.unitPrice"
                disabled
                placeholder="自动生成"
                size="mini"
              ></el-input>
              <el-input
                v-show="modalState != 5"
                @input="
                  taxRateGetInfo(item.taxRate, item, netArrUpdate2, index)
                "
                v-model="item.taxRate"
                size="mini"
                placeholder="整数如13"
              ></el-input>

              <el-input
                v-show="
                  modalState != 5 &&
                  modalState != 6 &&
                  permissoin.includes('money')
                "
                v-model="item.taxMoney"
                disabled
                placeholder="自动生成"
                size="mini"
              ></el-input>

              <el-input
                v-show="modalState != 5 && permissoin.includes('money')"
                @input="
                  taxUnitPriceGetInfo(
                    item.taxUnitPrice,
                    item,
                    netArrUpdate2,
                    index
                  )
                "
                v-model="item.taxUnitPrice"
                placeholder="请输入"
                size="mini"
              ></el-input>
              <span class="disabledred">
                <el-input
                  v-show="
                    modalState != 5 &&
                    modalState != 6 &&
                    permissoin.includes('money')
                  "
                  v-model="item.taxLastMoney"
                  disabled
                  placeholder="自动生成"
                  size="mini"
                ></el-input>
              </span>
              <div style="display: flex; margin-top: 6px">
                <p class="line"></p>
                <span
                  style="
                    cursor: pointer;
                    position: relative;
                    top: -6px;
                    color: #d62829;
                    margin-left: 6px;
                  "
                  @click="deletenvritem(index, 2)"
                  v-show="index > 0 && modalState == 0"
                  ><i class="el-icon-close">删除</i></span
                >
              </div>
            </div>
            <div
              class="text_item"
              v-show="modalState != 6 && netArrUpdate3.length > 0"
            >
              <span>*</span>其他服务
              <span v-show="modalState == 0" @click="addFormUpdateItem(3)">
                <i class="el-icon-plus"></i>
                增加服务</span
              >
            </div>
            <div
              v-show="modalState != 6"
              class="net"
              v-for="(item, index) in netArrUpdate3"
              :key="item.key"
            >
              <el-select
                v-model="item.materialId"
                clearable
                filterable
                size="mini"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in option3"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="item.operNumber"
                @input="checkNum(2, item, netArrUpdate3, index)"
                placeholder="请输入"
                size="mini"
              ></el-input>
              <el-input
                v-show="
                  modalState != 5 &&
                  modalState != 6 &&
                  permissoin.includes('money')
                "
                v-model="item.unitPrice"
                disabled
                placeholder="自动生成"
                size="mini"
              ></el-input>
              <el-input
                v-model="item.taxRate"
                v-show="modalState != 5"
                size="mini"
                placeholder="整数如13"
              ></el-input>
              <el-input
                v-show="
                  modalState != 5 &&
                  modalState != 6 &&
                  permissoin.includes('money')
                "
                v-model="item.taxMoney"
                disabled
                placeholder="自动生成"
                size="mini"
              ></el-input>
              <el-input
                v-show="modalState != 5 && permissoin.includes('money')"
                @input="
                  taxUnitPriceGetInfo(
                    item.taxUnitPrice,
                    item,
                    netArrUpdate3,
                    index
                  )
                "
                v-model="item.taxUnitPrice"
                placeholder="请输入"
                size="mini"
              ></el-input>
              <span class="disabledred">
                <el-input
                  v-show="
                    modalState != 5 &&
                    modalState != 6 &&
                    permissoin.includes('money')
                  "
                  v-model="item.taxLastMoney"
                  disabled
                  placeholder="自动生成"
                  size="mini"
                ></el-input>
              </span>

              <div style="display: flex; margin-top: 6px">
                <p class="line"></p>
                <span
                  style="
                    cursor: pointer;
                    position: relative;
                    top: -6px;
                    color: #d62829;
                    margin-left: 6px;
                  "
                  @click="deletenvritem(index, 3)"
                  v-show="index > 0 && modalState == 0"
                  ><i class="el-icon-close">删除</i></span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="bottom">
          <button
            class="btn_red"
            v-if="modalState == 0 && form.status == -1"
            @click="onsubmit10"
          >
            提交
          </button>
          <button
            class="btn_red"
            v-if="modalState == 0 && form.status == -2"
            @click="onsubmit"
          >
            保存
          </button>
          <button class="btn_gray" v-show="modalState == 0" @click="modal = 0">
            取消
          </button>
          <button
            class="btn_red"
            v-show="modalState == 4"
            @click="onsubmit1(4)"
          >
            通过
          </button>
          <button
            class="btn_gray"
            v-show="modalState == 4"
            @click="onsubmit1(2)"
          >
            驳回
          </button>
          <button
            class="btn_red"
            v-show="modalState == 5"
            @click="onsubmit5(5)"
          >
            指派实施
          </button>

          <button
            class="btn_red"
            v-show="modalState == 6 && slState == 0"
            @click="slState = 1"
          >
            申请领取
          </button>
          <button class="btn_red" v-show="slState == 1" @click="onsubmit6">
            确认申领
          </button>
        </div>
      </div>
    </div>
    <div class="indexModal" v-if="indexModal == 1">
      <DetailedList :data="transData" @close="close"></DetailedList>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import {
  distributorNoAdd,
  orderType,
  getUserByType,
  distributorGetList,
  distributorNoGet,
  distributorNoBatch,
  productGetList,
  distributorDelete,
  axiosUrl,
  wxGetOrgList,
  merchantGetList,
} from "@/api/apis.js";
import DetailedList from "@/components/detailedList.vue";
import { areaNameChange } from "@/utils/getTime.js";

export default {
  data() {
    return {
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //当天之后的时间可选
        },
      },
      option4: [],
      areaNameChange,
      option2: [],
      option3: [],
      option5: [],
      option6: [],
      option7: [],
      option50: [],
      indexModal: 0,
      transData: {},
      form: {
        allPrice: 0,
        allTax: 0,
        projectId: "",
        materialDate: "",
        remark: "",
        merchantId: "",
      },
      info: 1,
      psfrom: { s2: false, s3: false },
      currentPage2: 1,
      treeData: [],
      hide: 1,
      modal: 0,
      treeList: [],
      total: 0,
      searchForm: {},
      sn: "渠道计划（需要我方实施）",
      permissoin: [],
      deleteId: "",
      projectP: -1,

      dialogVisible: false,
      netArrUpdate1: [{ materialType: 1 }],
      netArrUpdate2: [{ materialType: 2 }],
      netArrUpdate3: [{ materialType: 3 }],
      ruleForm: {
        pwd: 123456,
      },
      price: {
        arr1: 0,
        arr11: 0,
        arr2: 0,
        arr22: 0,
        arr3: 0,
        arr33: 0,
      },
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
      option1: [
        {
          value: "1",
          label: "待审核",
        },
        {
          value: "-1",
          label: "已撤回",
        },
        {
          value: "4",
          label: "待分配",
        },
        {
          value: "5",
          label: "待申领",
        },
        {
          value: "6",
          label: "待出库",
        },
        {
          value: "3",
          label: "待实施",
        },
        {
          value: "0",
          label: "已完成",
        },
        {
          value: "2",
          label: "被驳回",
        },
        {
          value: "7",
          label: "安装待确认",
        },
        {
          value: "8",
          label: "安装失败",
        },
        {
          value: "9",
          label: "安装退回",
        },
      ],
      slState: 0,
      modalState: 0,
      netArrUpdate4: [],
      tableData: [],
      srcList: [],
      rules: {
        orgId: [{ required: true, message: "选择机构", trigger: "blur" }],
      },
    };
  },
  components: {
    DetailedList,
  },
  methods: {
    getData() {
      let data = {
        status: this.searchForm.status,
        handsPersonId: this.searchForm.handsPersonId,
        startTime: this.searchForm.materialDate? this.searchForm.materialDate[0] : "",
        endTime: this.searchForm.materialDate? this.searchForm.materialDate[1] : "",
        keywords: this.searchForm.keywords,
        isMaterial: 1,
        pageNum: this.currentPage2,
        pageSize: 10,
      };
      distributorGetList(data).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total - 0;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
     fmoney(val) {
       //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
        var str = val.toFixed(2) + '';
        var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
        var dot = str.substring(str.length,str.indexOf("."))//取到小数部分搜索
        var ret = intSum + dot;
        return ret;
    },
    deletePro() {
      distributorDelete(this.deleteId).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.dialogVisible = false;
          this.getData();
        } else {
          this.$message.error(res.data.info);
        }
      });
    },
    textExport() {
      let data = {
        status: this.searchForm.status,
        keywords: this.searchForm.keywords,
        isMaterial: 1,
      };
      location.href =
        axiosUrl + "/admin/l/order/distributor/export?" + qs.stringify(data);
    },
    close() {
      this.indexModal = 0;
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    checkNum(ii, item, arr, i, a, b) {
      if (ii == 1) {
        let num = 0;
        this.option4.forEach((item) => {
          if (item.id == b) {
            num = item.stock;
          }
        });
        if (a > num) {
          this.$message.error("申请数量不能超过库存");
          this.netArrUpdate1[i].operNumber = 0;
        }
      }
      if (item.taxUnitPrice && item.taxRate) {
        this.taxUnitPriceGetInfo(arr[i].taxUnitPrice, arr[i], arr, i);
      }
    },
    //输入税率
    taxRateGetInfo(taxRate, item, arr, index) {
      if (!item.materialId || !item.operNumber) {
        this.$message.error("请先输入产品和数量");
        arr[index].taxRate = "";
      } else {
        if (item.taxUnitPrice) {
          arr[index].taxLastMoney =
            arr[index].taxUnitPrice * arr[index].operNumber;
          arr[index].taxMoney = (
            (arr[index].taxUnitPrice * arr[index].operNumber * taxRate) /
            (100 + (taxRate - 0))
          ).toFixed(2);
          arr[index].unitPrice = (
            (arr[index].taxUnitPrice * 100) /
            (100 + (taxRate - 0))
          ).toFixed(2);
        }
      }
    },
    //输入税金
    taxUnitPriceGetInfo(taxUnitPrice, item, arr, index) {
      if (!item.materialId || !item.operNumber) {
        this.$message.error("请先输入产品和数量");
      } else {
        arr[index].taxLastMoney = taxUnitPrice * arr[index].operNumber;
        if (item.taxRate) {
          arr[index].taxMoney = (
            (taxUnitPrice * arr[index].operNumber * item.taxRate) /
            (100 + (item.taxRate - 0))
          ).toFixed(2);
          arr[index].unitPrice = (
            (taxUnitPrice * 100) /
            (100 + (item.taxRate - 0))
          ).toFixed(2);
        }
      }
    },
    onsubmit() {
      let flag = true;
      if (!this.form.projectId) {
        this.$message.error("请选择项目");
        flag = false;
      }
      if (!this.form.materialDate) {
        this.$message.error("请选择时间");
        flag = false;
      }

      // if (
      //   !this.netArrUpdate1[0].materialId ||
      //   !this.netArrUpdate1[0].operNumber ||
      //   !this.netArrUpdate1[0].taxRate ||
      //   !this.netArrUpdate1[0].taxUnitPrice
      // ) {
      //   this.$message.error("请完善信息");
      //   flag = false;
      // }
      let arr = [];
      if (this.netArrUpdate1[0].materialId) {
        arr = arr.concat(this.netArrUpdate1);
      }
      if (this.netArrUpdate2[0].materialId) {
        arr = arr.concat(this.netArrUpdate2);
      }
      if (this.netArrUpdate3[0].materialId) {
        arr = arr.concat(this.netArrUpdate3);
      }
      if (flag) {
        arr = JSON.parse(JSON.stringify(arr)).map((item) => {
          item.taxRate = item.taxRate / 100;
          return item;
        });
        let data = {
          projectId: this.form.projectId,
          merchantId: this.form.merchantId,
          isMaterial: 1,
          materialDate: this.form.materialDate,
          allPrice: this.form.allPrice,
          allTax: this.form.allTax,
          remark: this.form.remark,
          list: arr,
        };
        distributorNoAdd(data).then((res) => {
          if (res.code == 200) {
            this.getData();
            this.$message({
              type: "success",
              message: res.info,
            });
            this.modal = 0;
            this.netArrUpdate1 = [{ materialType: 1 }];
            this.netArrUpdate2 = [{ materialType: 2 }];
            this.netArrUpdate3 = [{ materialType: 3 }];
            this.form = { allPrice: 0, allTax: 0 };
          } else {
            this.$message.error(res.data.info);
          }
        });
      }
    },
    onsubmit10() {
      let flag = true;
      if (!this.form.projectId) {
        this.$message.error("请选择项目");
        flag = false;
      }
      if (!this.form.materialDate) {
        this.$message.error("请选择时间");
        flag = false;
      }

      // if (
      //   !this.netArrUpdate1[0].materialId ||
      //   !this.netArrUpdate1[0].operNumber ||
      //   !this.netArrUpdate1[0].taxRate ||
      //   !this.netArrUpdate1[0].taxUnitPrice
      // ) {
      //   this.$message.error("请完善信息");
      //   flag = false;
      // }
      let arr = [];
      if (this.netArrUpdate1[0].materialId) {
        arr = arr.concat(this.netArrUpdate1);
      }
      if (this.netArrUpdate2[0].materialId) {
        arr = arr.concat(this.netArrUpdate2);
      }
      if (this.netArrUpdate3[0].materialId) {
        arr = arr.concat(this.netArrUpdate3);
      }
      if (flag) {
        arr = JSON.parse(JSON.stringify(arr)).map((item) => {
          item.taxRate = item.taxRate / 100;
          return item;
        });
        let data = {
          projectId: this.form.projectId,
          merchantId: this.form.merchantId,
          isMaterial: 1,
          materialDate: this.form.materialDate,
          allPrice: this.form.allPrice,
          allTax: this.form.allTax,
          remark: this.form.remark,
          id: this.deleteId,
          status: this.form.orderType == 1 ? 1 : 4,
          list: arr,
        };
        distributorNoAdd(data).then((res) => {
          if (res.code == 200) {
            this.getData();
            this.$message({
              type: "success",
              message: res.info,
            });
            this.modal = 0;
            this.netArrUpdate1 = [{ materialType: 1 }];
            this.netArrUpdate2 = [{ materialType: 2 }];
            this.netArrUpdate3 = [{ materialType: 3 }];
            this.form = { allPrice: 0, allTax: 0 };
          } else {
            this.$message.error(res.data.info);
          }
        });
      }
    },
    deletenvritem(index, i) {
      if (i == 1) {
        this.netArrUpdate1.splice(index, 1);
      } else if (i == 2) {
        this.netArrUpdate2.splice(index, 1);
      } else if (i == 3) {
        this.netArrUpdate3.splice(index, 1);
      }
    },
    addFormUpdateItem(i) {
      if (i == 1) {
        this.$set(this.netArrUpdate1, this.netArrUpdate1.length, {
          key: this.netArrUpdate1.length + ",",
          materialType: 1,
        });
      } else if (i == 2) {
        this.$set(this.netArrUpdate2, this.netArrUpdate2.length, {
          key: this.netArrUpdate2.length + ",",
          materialType: 2,
        });
      } else if (i == 3) {
        this.$set(this.netArrUpdate3, this.netArrUpdate3.length, {
          key: this.netArrUpdate3.length + ",",
          materialType: 3,
        });
      }
    },
    //审核
    onsubmit1(i) {
      distributorNoAdd({ id: this.form.id, status: i ,auditOpinion:this.form.auditOpinion}).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: res.info,
          });
          this.getData();
          this.modal = 0;
          this.netArrUpdate1 = [{ materialType: 1 }];
          this.netArrUpdate2 = [{ materialType: 2 }];
          this.netArrUpdate3 = [{ materialType: 3 }];
          this.form = { allPrice: 0, allTax: 0 };
        } else {
          this.$message.error(res.data.info);
        }
      });
    },
    onsubmit11() {
      distributorNoAdd({
        id: this.deleteId,
        status: -1,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: res.info,
          });
          this.getData();
          this.modal = 0;
          this.netArrUpdate1 = [{ materialType: 1 }];
          this.netArrUpdate2 = [{ materialType: 2 }];
          this.netArrUpdate3 = [{ materialType: 3 }];
          this.form = {
            allPrice: 0,
            allTax: 0,
            projectId: "",
            materialDate: "",
            remark: "",
            merchantId: "",
          };
        } else {
          this.$message.error(res.data.info);
        }
      });
    },
    onsubmit6() {
      let arr = [];
      this.netArrUpdate4.forEach((item) => {
        item.orderId = this.form.orderId;
        arr.push(item);
      });
      distributorNoBatch({ applyList: arr, id: this.form.id }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: res.info,
          });
          this.getData();
          this.modal = 0;
          this.netArrUpdate1 = [{ materialType: 1 }];
          this.netArrUpdate2 = [{ materialType: 2 }];
          this.netArrUpdate3 = [{ materialType: 3 }];
          this.netArrUpdate4 = [];
          this.form = { allPrice: 0, allTax: 0 };
        } else {
          this.$message.error(res.data.info);
        }
      });
      this.slState = 0;
    },
    onsubmit5(i) {
      let flag = true;
      if (!this.form.handsPersonId) {
        this.$message.error("请选择实施人员");
        flag = false;
      }
      if (flag) {
        distributorNoAdd({
          id: this.form.id,
          status: i,
          handsPersonId: this.form.handsPersonId,
        }).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.info,
            });
            this.getData();
            this.modal = 0;
            this.netArrUpdate1 = [{ materialType: 1 }];
            this.netArrUpdate2 = [{ materialType: 2 }];
            this.netArrUpdate3 = [{ materialType: 3 }];
            this.form = { allPrice: 0, allTax: 0 };
          } else {
            this.$message.error(res.data.info);
          }
        });
      }
    },
    handleCommand(d) {
      this.deleteId = d.data.id;
      if (d.num == 2) {
        //查看详情
        distributorNoGet(d.data.id).then((res) => {
          if (res.code == 200) {
            if (d.data.status == -1) {
              this.modalState = 0;
            } else {
              this.modalState = d.num;
            }
            this.modal = 1;
            this.form.orderId = res.data.order.id;
            this.form.materialRemark = res.data.order.materialRemark;
            if (res.data.order.file) {
              this.srcList = res.data.order.file.split(",");
            } else {
              this.srcList = [];
            }
            if (d.data.status == 2) {
              this.form.auditOpinion = res.data.order.auditOpinion;
            }
            this.form.status = res.data.order.status;
            this.form.orderType = res.data.order.orderType;
            this.form.merchantId = res.data.order.merchantId;
            this.form.projectId = res.data.order.projectId;
            this.form.jh =
              res.data.order.orderType == 1 ? "初装计划" : "补充计划";
            this.form.materialDate = res.data.order.materialDate;
            this.form.remark = res.data.order.remark;
            this.form.allTax = res.data.order.allTax;
            this.form.allPrice = res.data.order.allPrice;
            this.form.yjcb = res.data.order.yjcb;
            this.form.sj = res.data.order.sj;
            this.form.glf = res.data.order.glf;
            this.form.swf = res.data.order.swf;            
            this.form.mlr = res.data.order.mlr;
            this.form.mlv = (res.data.order.mlv*100).toFixed(2)+'%';
            if (res.data.硬件材料 != null) {
              this.netArrUpdate1 = res.data.硬件材料.map((item) => {
                item.taxRate = item.taxRate * 100;
                return item;
              });
            } else {
              this.netArrUpdate1 = [];
            }

            if (res.data.软件系统 != null) {
              this.netArrUpdate2 =
                res.data.软件系统.map((item) => {
                  item.taxRate = item.taxRate * 100;
                  return item;
                }) || [];
            } else {
              if (d.data.status == -1) {
                this.netArrUpdate2 = [{ materialType: 2 }];
              } else {
                this.netArrUpdate2 = [];
              }
            }
            if (res.data.其他服务 != null) {
              this.netArrUpdate3 =
                res.data.其他服务.map((item) => {
                  item.taxRate = item.taxRate * 100;
                  return item;
                }) || [];
            } else {
              if (d.data.status == -1) {
                this.netArrUpdate3 = [{ materialType: 3 }];
              } else {
                this.netArrUpdate3 = [];
              }
            }
          } else {
            this.$message.error(res.data.info);
          }
        });
      } else if (d.num == 3) {
        this.dialogVisible = true;
      } else if (d.num == 4 || d.num == 5 || d.num == 6) {
        //查看详情
        distributorNoGet(d.data.id).then((res) => {
          if (res.code == 200) {
            this.modalState = d.num;
            this.modal = 1;
            if (d.data.status == 2) {
              this.form.auditOpinion = res.data.order.auditOpinion;
            }
            this.form.orderId = res.data.order.id;
            this.form.merchantId = res.data.order.merchantId;
            this.form.status = res.data.order.status;
            this.form.projectId = res.data.order.projectId;
            this.form.jh =
              res.data.order.orderType == 1 ? "初装计划" : "补充计划";
            this.form.materialDate = res.data.order.materialDate;
            this.form.remark = res.data.order.remark;
            this.form.allTax = res.data.order.allTax;
            this.form.allPrice = res.data.order.allPrice;
            this.form.yjcb = res.data.order.yjcb;
            this.form.sj = res.data.order.sj;
            this.form.glf = res.data.order.glf;
            this.form.swf = res.data.order.swf;            
            this.form.mlr = res.data.order.mlr;
            this.form.mlv = (res.data.order.mlv*100).toFixed(2)+'%';
            if (res.data.硬件材料 != null) {
              this.netArrUpdate1 = res.data.硬件材料.map((item) => {
                item.taxRate = item.taxRate * 100;
                return item;
              });
            } else {
              this.netArrUpdate1 = [];
            }

            if (res.data.软件系统 != null) {
              this.netArrUpdate2 =
                res.data.软件系统.map((item) => {
                  item.taxRate = item.taxRate * 100;
                  return item;
                }) || [];
            } else {
              this.netArrUpdate2 = [];
            }
            if (res.data.其他服务 != null) {
              this.netArrUpdate3 =
                res.data.其他服务.map((item) => {
                  item.taxRate = item.taxRate * 100;
                  return item;
                }) || [];
            } else {
              this.netArrUpdate3 = [];
            }
            this.form.id = d.data.id;
            if (d.num == 6) {
              this.netArrUpdate4 = [];
              res.data.硬件材料.forEach((item) => {
                this.netArrUpdate4.push({
                  productId: item.materialId,
                  planNumber: item.operNumber,
                  applyNumber: item.operNumber,
                });
              });
            }
          } else {
            this.$message.error(res.data.info);
          }
        });
      } else if (d.num == -1) {
        this.onsubmit11();
      }
    },
    getprice() {
      this.form.allTax = (
        this.price.arr11 +
        this.price.arr22 +
        this.price.arr33
      ).toFixed(2);
      this.form.allPrice = this.price.arr1 + this.price.arr2 + this.price.arr3;
    },
    pjChange(projectId) {
      let data = { projectId };
      orderType(data).then((res) => {
        this.form.jh = res.data.typeName;
        this.$forceUpdate();
      });
    },
  },
  mounted() {
    this.getData();
    //获取权限
    let arr = JSON.parse(localStorage.getItem("perArr"));
    let routePath = this.$route.path;
    arr.forEach((item) => {
      if (item.purl == routePath) {
        this.permissoin.push(item.permission);
      }
    });
    productGetList({ pageSize: 0 }).then((res) => {
      this.option4 = res.data.list.filter(
        (item) => item.type == 1 || item.type == 5
      );
      this.option2 = res.data.list.filter((item) => item.type == 2);
      this.option3 = res.data.list.filter((item) => item.type == 3);
    });
    wxGetOrgList({ pageSize: 0 }).then((res) => {
      if (res.code == 200) {
        this.option5 = res.data.list;
      }
    });
    getUserByType({ type: 23 }).then((res) => {
      if (res.code == 200) {
        this.option6 = res.data;
      }
    });
    merchantGetList({ pageSize: 0 }).then((res) => {
      this.option50 = res.data.list.filter((item) => item.type == 2);
    });
  },
  watch: {
    netArrUpdate1: {
      deep: true, //深度监听
      handler: function () {
        let num1 = 0;
        let num2 = 0;
        this.netArrUpdate1.forEach((item) => {
          num1 += (item.taxUnitPrice - 0) * (item.operNumber - 0);
          num2 +=
            ((item.taxUnitPrice - 0) *
              (item.operNumber - 0) *
              (item.taxRate - 0)) /
            (100 + (item.taxRate - 0));
        });
        this.price.arr1 = isNaN(num1) ? 0 : num1;
        this.price.arr11 = isNaN(num2) ? 0 : num2;
        this.getprice();
      },
    },
    netArrUpdate2: {
      deep: true, //深度监听
      handler: function () {
        let num1 = 0;
        let num2 = 0;
        this.netArrUpdate2.forEach((item) => {
          num1 += (item.taxUnitPrice - 0) * (item.operNumber - 0);
          num2 +=
            ((item.taxUnitPrice - 0) *
              (item.operNumber - 0) *
              (item.taxRate - 0)) /
            (100 + (item.taxRate - 0));
        });
        this.price.arr2 = isNaN(num1) ? 0 : num1;
        this.price.arr22 = isNaN(num2) ? 0 : num2;
        this.getprice();
      },
    },
    netArrUpdate3: {
      deep: true, //深度监听
      handler: function () {
        let num1 = 0;
        let num2 = 0;
        this.netArrUpdate3.forEach((item) => {
          num1 += (item.taxUnitPrice - 0) * (item.operNumber - 0);
          num2 +=
            ((item.taxUnitPrice - 0) *
              (item.operNumber - 0) *
              (item.taxRate - 0)) /
            (100 + (item.taxRate - 0));
        });
        this.price.arr3 = isNaN(num1) ? 0 : num1;
        this.price.arr33 = isNaN(num2) ? 0 : num2;
        this.getprice();
      },
    },
  },
};
</script>

<style lang="less" >
.Device1122 {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main111 {
    flex: 1;
    background-color: #fff;
    display: flex;
  }
  .index_right {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .search {
      margin-bottom: 22px;
      .el-input {
        width: 208px;
        margin-right: 20px;
      }
    }
    .table {
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 1200px;
      height: 800px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .spa {
        display: inline-block;
        width: 80px;
        position: relative;
        left: 10px;
      }
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        height: 690px;
        padding-left: 34px;
        box-sizing: border-box;
        padding-top: 20px;
        .el-button--mini,
        .el-button--mini.is-round {
          padding: 6px 12px;
          margin-left: 4px;
        }
        .ck {
          color: rgb(17, 85, 211);
          cursor: pointer;
          font-size: 12px;
          margin-left: 20px;
        }
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            margin-left: 198px;
            cursor: pointer;
            color: #d62829;
            i {
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
        .jstitle {
           margin-bottom: 12px;
          span {
            display: inline-block;
            margin-right: 12px;
          }
          & > span:nth-child(1) {
            width: 150px;
          }
          & > span:nth-child(2) {
            width: 80px;
          }
          & > span:nth-child(3) {
            width: 80px;
          }
          & > span:nth-child(4) {
            width: 80px;
          }
          & > span:nth-child(5) {
            width: 80px;
          }
          & > span:nth-child(6) {
            width: 80px;
          }
          & > span:nth-child(7) {
            width: 80px;
          }
        }
        .net {
          .line {
            border-top: 1px dashed rgb(221, 214, 214);
            width: 656px;
          }
          p {
            margin-bottom: 20px;
          }
          .spaa {
            display: inline-block;
            width: 80px;
          }
          .el-select {
            width: 150px;
            margin-right: 12px;
            .el-input {
              width: 150px;
            }
          }
          .el-input {
            width: 80px;
            margin-right: 12px;
            .el-input__inner {
              border: none;
              padding: 0;
            }
          }
          .star {
            color: #ff2825;
            font-size: 14px;
            position: relative;
            top: 2px;
          }
        }

        .line {
          border-top: 1px dashed rgb(221, 214, 214);
          width: 388px;
        }
        p {
          margin-bottom: 20px;
        }
        .spaa {
          display: inline-block;
          width: 80px;
        }
        .el-input {
          width: 300px;
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
      }
      .bottom {
        height: 60px;
        display: flex;
        justify-content: center;
        .btn_red {
          margin-right: 20px;
        }
      }
    }
  }
  .indexModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    align-items: center;
  }
}
</style>