<template>
  <div class="channel" ref="vlbox">
    <div class="index_main" ref="index_main">
        <p class="tt">
           <span @click="state=0" :class="{'active':state==0}">无需安装</span>
           <span @click="state=1" :class="{'active':state==1}">需要安装</span>
        </p>
        <div class="md">
         <ChannelNo v-if="state==0"></ChannelNo>
      <ChannelNeed  v-if="state==1"></ChannelNeed> 
        </div>      
    </div>
  </div>
</template>

<script>
import ChannelNo from "@/components/ChannelNo.vue";
import ChannelNeed from "@/components/ChannelNeed.vue";
export default {
  data() {
    return {
      state:1,
    }
  },
  components: {
    ChannelNo,ChannelNeed
  },
  
};
</script>

<style lang="less" >
.channel{
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main{
    flex: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    .tt{
      line-height: 40px;
      font-size: 14px;
      color: #999;
      border-bottom: 1px solid #ebf0f5;
      span{
        cursor: pointer;
        margin-left: 20px;
      }
      .active{
        color: #2b2e36;;
      }
    }
    .md{
      flex: 1;
    }
  }
}
</style>